@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
@tailwind base;
@tailwind components;
@tailwind transitions;
@tailwind utilities;

body {
  color: #352641CC;
}

.text-first-up::first-letter,
.text-first-up:first-letter {
  text-transform: uppercase;
}

/*
*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*/

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
.min-h-screen {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100) !important;
}

.max-h-screen {
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100) !important;
}

.h-screen {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100) !important;
}
